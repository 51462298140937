import { AdminSearchWrapper, AdminDetails } from './components';

const routes = [
  {
    path: '/admins',
    name: 'Admins',
    restrict: ['abode'],
    abilities: 'ReadAdmins',
    breadcrumb: {
      label: 'Admins',
      icon: {
        symbol: 'Binocular',
        library: 'general',
      },
    },
    children: [
      {
        path: '/',
        name: 'Admin Search',
        component: AdminSearchWrapper,
        exact: true,
        restrict: ['abode'],
      },
      {
        path: '/:id(\\d+)',
        name: 'AdminAdminDetails',
        component: AdminDetails,
        exact: true,
        restrict: ['abode'],
        breadcrumb: {
          label: 'Admin details',
        },
      },
    ],
  },
];

export default routes;
